import React from 'react'
import { List, BooleanField, Datagrid, TextField, ReferenceField,DateField, EditButton,NumberField,
  DeleteButton, Edit, SimpleForm,ReferenceInput,SelectArrayInput,ReferenceManyField,ChipField,SingleFieldList,
  ReferenceArrayInput, SelectInput,required, NumberInput,
  TextInput, BooleanInput, Create  } from 'react-admin'

export const MMSProviderList = () => {
  return <List title="List of MMS Providers">
    <Datagrid >
      <TextField source="id"/>
        <TextField source="hostName"  emptyText="missing data"  />
      <NumberField source="port" label="Port"/>
      <TextField source="smsgateDns"   label="SMSGate DNS"/>
      <TextField source="description"  label="Description"/>
      <BooleanField source="isSecure"  label="Is Secure" />
      <EditButton />
      <DeleteButton/>
    </Datagrid>
  </List>
}

export const MMSProviderEdit= () => {
  return (
    <Edit title='Edit MMS Provider'>
      <SimpleForm>
        <TextInput disabled source='id'/>
        <TextInput source="hostName"/>
        <TextInput source="description"/>
        <NumberInput source="port"  label="Port" />
        <TextInput source="smsgateDns" label="SMS Gate DNS" />
        <BooleanInput source="isSecure" label="is Secure" />
      </SimpleForm>
    </Edit>
  )
}

export const MMSProviderCreate= () => {
  return (
    <Create title='Create MMS Provider'>
      <SimpleForm>
        <TextInput required source="hostName"  label="Hostname" />
        <NumberInput required source="port"  label="Port" />
        <BooleanInput source="isSecure"  label="Is Secure" />
        <TextInput required source="smsgateDns"  label="SMSgate DNS" />
      </SimpleForm>
    </Create>
  )
}

